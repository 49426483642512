// src/pages/Admin.jsx
import { useState, useEffect } from 'react';
import { Menu, Upload, Button, message, Table, Switch } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { getAllFilesFunction, uploadFileFunction, getClientsFromMayorista, updateClientSalesVisibility } from '@/requests/dataService';
import moment from 'moment';

const Admin = () => {
  const [selectedType, setSelectedType] = useState('cost');
  const [fileList, setFileList] = useState([]);
  const [clientList, setClientList] = useState([]);

  useEffect(() => {
    fetchClientList();
  }, []);

  const handleMenuClick = (e) => {
    setSelectedType(e.key);
    if (e.key !== 'clients') {
      fetchFileList(e.key);
    }
  };

  const fetchFileList = (type) => {
    getAllFilesFunction(type)
      .then(response => {
        setFileList(response);
      })
      .catch(error => {
        console.error('There was an error fetching the file list!', error);
      });
  };

  const fetchClientList = () => {
    getClientsFromMayorista()
      .then(response => {
        setClientList(response);
      })
      .catch(error => {
        console.error('There was an error fetching the client list!', error);
      });
  };

  const handleUpload = async (info) => {
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
      fetchFileList(selectedType);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const customUpload = async ({ file }) => {
    uploadFileFunction(file, selectedType)
      .then(() => {
        console.log('File uploaded successfully');
        handleUpload({ file: { status: 'done', name: file.name } });
      })
      .catch((error) => {
        console.error('File upload failed:', error);
        handleUpload({ file: { status: 'error', name: file.name } });
      });
  };

  const uploadProps = {
    customRequest: customUpload,
    onChange: handleUpload,
  };

  const handleSwitchChange = (checked, client) => {
    const updatedClientList = clientList.map(c =>
      c.client_name === client.client_name ? { ...c, considered_sale: checked } : c
    );
    setClientList(updatedClientList);
  };

  const handleUpdateClientVisibility = () => {
    const clientsData = clientList.map(client => ({
      client_name: client.client_name,
      show_sales: client.considered_sale
    }));

    updateClientSalesVisibility({ clients: clientsData })
      .then(() => {
        message.success('Client sales visibility updated successfully');
      })
      .catch(error => {
        console.error('Error updating client sales visibility:', error);
        message.error('Failed to update client sales visibility');
      });
  };

  const fileColumns = [
    {
      title: 'File Name',
      dataIndex: 'file_name',
      key: 'file_name',
    },
    {
      title: 'Upload Date',
      dataIndex: 'created',
      key: 'created',
      render: (text) => moment(text).format('DD-MM-YYYY'),
    },
    {
      title: 'Status',
      dataIndex: 'get_status_display',
      key: 'get_status_display',
    },
  ];

  const clientColumns = [
    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
    },
    {
      title: 'Consider Sales',
      dataIndex: 'considered_sale',
      key: 'considered_sale',
      render: (text, record) => (
        <Switch
          checked={record.considered_sale}
          onChange={(checked) => handleSwitchChange(checked, record)}
        />
      ),
    },
  ];

  return (
    <div style={{ padding: '20px' }}>
      <h1>Admin Panel</h1>
      <Menu onClick={handleMenuClick} selectedKeys={[selectedType]} mode="horizontal">
        <Menu.Item key="cost">Archivos de costos</Menu.Item>
        <Menu.Item key="estimated-sales">Archivos de ventas estimadas</Menu.Item>
        <Menu.Item key="stock">Archivos de Stock</Menu.Item>
        <Menu.Item key="clients">Considerar Clientes Mayorista</Menu.Item>
      </Menu>
      <div style={{ marginTop: '20px' }}>
        {selectedType !== 'clients' ? (
          <>
            <Upload {...uploadProps}>
              <Button icon={<UploadOutlined />}>Upload {selectedType.replace('-', ' ').toUpperCase()} File</Button>
            </Upload>
            <Table dataSource={fileList} columns={fileColumns} rowKey="id" style={{ marginTop: '20px' }} />
          </>
        ) : (
          <>
            <Table dataSource={clientList} columns={clientColumns} rowKey="client_name" style={{ marginTop: '20px' }} />
            <Button type="primary" onClick={handleUpdateClientVisibility} style={{ marginTop: '20px' }}>
              Actualizar Visibilidad de Ventas de Clientes
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default Admin;
